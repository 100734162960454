import { useToastError } from '../useToastError'
import { downloadFile } from '@/api/upload'
import { getS3Object } from '@/helpers/getS3Object'

export const downloadFileKey = async (fileKey: string, fileName?: string) => {
    try {
        const url = await getS3Object(fileKey, fileName)

        if (url) {
            await downloadFile(url, fileName)
        }
    } catch (err) {
        useToastError({
            description: (err as Error).message
        })
    }
}
