import { apiClient } from './client'
import type { AuthResponse, OnboardingProperty } from '@/types/Auth'

export const postGoogle = async (token: string, rememberMe: boolean, onboardingData?: OnboardingProperty): Promise<AuthResponse> => {
    const authCredentials: AuthResponse = await apiClient('/auth/google', {
        method: 'POST',
        body: {
            token,
            rememberMe,
            onboardingData
        }
    })
    return authCredentials
}
