import { apiClient } from './client'

export const deleteMediaFromBucket = (fileKeys: string[]): Promise<{ success: true } | { error: string }> => {
    return apiClient('/upload/delete-media', {
        method: 'DELETE',
        body: {
            fileKeys: fileKeys.map(value => value.replace('/thumbnail/', '/photos/'))
        },
        keepalive: true
    })
}

export const downloadFile = (url:string, fileName?:string) => {
    const a = document.createElement('a')
    a.href = url
    a.setAttribute('download', fileName || 'downloadFile')
    a.click()
}
